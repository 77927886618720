import React, { useState } from "react";

const ItemList = () => {
  // eslint-disable-next-line
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [templateItems, setTemplateItems] = useState([]);

  // List of available items
  const itemList = [
    { id: 1, name: "Item 1", price: 10 },
    { id: 2, name: "Item 2", price: 20 },
    { id: 3, name: "Item 3", price: 30 },
  ];

  // Add an item to the template
  const addItemToTemplate = () => {
    const newItem = {
      name: selectedItem,
      price: price,
      quantity: quantity,
    };

    setTemplateItems([...templateItems, newItem]);
  };

  return (
    <div>
      <h1>Item List</h1>
      <div>
        <label>Select Item: </label>
        <select
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
        >
          <option value="">Select an item</option>
          {itemList.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Price: </label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </div>
      <div>
        <label>Quantity: </label>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      <button onClick={addItemToTemplate}>Add to Template</button>
      <h2>Template</h2>
      <ul>
        {templateItems.map((item, index) => (
          <li key={index}>
            {item.name} - ${item.price} - Quantity: {item.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemList;
